
import { Component, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { Validate } from 'vuelidate-property-decorators'
import { minLength } from 'vuelidate/lib/validators'
import { Location } from '@/interfaces/locations.interface'
import {
  createLocation,
  updateLocation,
  getLocationById,
} from '@/services/locations.service'

@Component({
  components: {
    SellerHeader,
  },
})
export default class SellerForm extends Vue {
  @Validate({ minLength: minLength(3) }) name = ''
  @Validate({ minLength: minLength(3) }) address = ''
  status = false

  title = 'Crear Local'
  editMode = false
  options: [] = []
  location: Location = {
    name: '',
    id: null,
    address: '',
    status: false,
    business: undefined,
    sellers: undefined,
  }

  locationId = ''

  async submitEdit() {
    try {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        const data = {
          name: this.name,
          address: this.address,
          status: this.status,
        }

        const response = await updateLocation(this.locationId, data)
        console.log(response)
        this.name = ''
        this.address = ''

        this.$router.push('/seller/administration/')
      }
    } catch (error) {
      console.error(error)
    }
  }

  async submitCreate() {
    try {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        const data = {
          name: this.name,
          address: this.address,
        }

        const response = await createLocation(data)
        console.log(response)
        this.name = ''
        this.address = ''

        this.$router.push('/seller/administration/')
      }
    } catch (error) {
      console.error(error)
    }
  }

  async getLocationById(id: string) {
    const { name, address, status } = await getLocationById(id)

    if (name && address) {
      this.name = name
      this.address = address
      this.status = status
    }
  }

  mounted() {
    if (this.$route.params.id) {
      this.title = 'Editar Local'
      this.locationId = this.$route.params.id
      this.editMode = true

      this.getLocationById(this.locationId)
    }
  }
  get isDisabled() {
    return !(
      this.name &&
      this.address &&
      !this.$v.name.$invalid &&
      !this.$v.address.$invalid
    )
  }
}
